import { defineStore } from 'pinia';
import type { ID } from '@repo/style3d';
import { computed, ref } from 'vue';
import { useRelationStore } from './relation.ts';


export const useSelectionStore = defineStore('selection', () => {
  const selectedFabricId = ref<ID>('');

  function setSelectedFabric(id: ID) {
    selectedFabricId.value = id;
  }

  const relationStore = useRelationStore();
  const lastSelectedFabricId = computed(() => {
    if (!selectedFabricId.value) {
      const found = relationStore.relation?.shape.find(item => item.type === 'main');
      if (found) {
        return found.id;
      } else {
        return '';
      }
    }
    return selectedFabricId.value;
  });


  return {
    setSelectedFabric,
    lastSelectedFabricId
  };
});
